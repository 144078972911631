<template>
  <div>
    <div :class="{'opacity-30': !getSaveLink.showFavicon}"  v-if="toggle">
      <p class="text !text-[#202324] font-bold">Customize Favicon</p>
      <URLInputFloatingV2
        class="mt-6"
        id="meta_image_url"
        v-model="getSaveLink.custom_favicon_url"
        :validations="URLValidations"
        :disabled="!getSaveLink.showFavicon"
        type="text"
        label="Meta Image URl"
        customClass="h-[3.5rem] bg-[#F4F6FA]"
        :errorType="URLValidations.url || URLValidations.urlLength || URLValidations.urlValid  ? 'danger' : ''"
      >
        <template v-slot:error_message>
          <InputFieldMessage v-if="URLValidations.url" :message="URLMessages.url"></InputFieldMessage>
          <InputFieldMessage v-else-if="URLValidations.urlLength" :message="URLMessages.urlLength"></InputFieldMessage>
          <InputFieldMessage v-else-if="URLValidations.urlValid" :message="URLMessages.urlValid"></InputFieldMessage>
        </template>
      </URLInputFloatingV2>
      <ImageUploadV2
        customClass="mt-[0.75rem] h-[16rem]"
        :image="getSaveLink.custom_favicon_url"
        :disabled="!getSaveLink.showFavicon"
        @click="getSaveLink.custom_favicon_url = $event"
      ></ImageUploadV2>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  URLMessages,
  URLValidations
} from '@/common/attributes'

export default {
  props: ['toggle'],
  computed: {
    ...mapGetters([
      'getSaveLink'
    ])
  },
  data() {
    return {
      URLValidations: URLValidations,
      URLMessages: URLMessages
    }
  },
  components: {
    URLInputFloatingV2: () => import('@/ui/ui-kit/v2/URLInputFloatingV2.vue'),
    InputFieldMessage: () => import('@/ui/ui-kit/v2/InputFieldMessage.vue'),
    ImageUploadV2: () => import('@/ui/ui-kit/v2/ImageUploadV2.vue'),
  },
  methods: {
    validateFavicon() {
      let validate = false
      if (this.getSaveLink.showFavicon) {
        this.URLValidations.url = this.requiredCheck(this.getSaveLink.custom_favicon_url)
        this.URLValidations.urlLength = !this.maxLength(this.getSaveLink.custom_favicon_url, 500)

        if(this.getSaveLink.custom_favicon_url && this.getSaveLink.custom_favicon_url.length) {
          this.getSaveLink.seo.image = this.urlConcatenation(this.getSaveLink.custom_favicon_url)
          this.URLValidations.urlValid = !this.isValidURL(this.getSaveLink.custom_favicon_url)
        }

        validate = Object.keys(this.URLValidations).every(k => this.URLValidations[k] == false)
      }
      return validate
    }
  },
  watch: {
    'getSaveLink.custom_favicon_url' (value) {
      this.URLValidations.url = this.requiredCheck(value)
      this.URLValidations.urlLength = !this.maxLength(value, 500)
      this.getSaveLink.custom_favicon_url = this.urlConcatenation(this.getSaveLink.custom_favicon_url)
      this.URLValidations.urlValid = !this.isValidURL(this.getSaveLink.custom_favicon_url)
    },
  }
}
</script>
